import { takeLatest, call, put, take } from 'redux-saga/effects'
import { authActions } from '../slices/authSlice'
import * as authService from '../../services/auth.service'
import LocalStorage from 'utils/localStorage'
import { domainForlifeHome } from 'config'
import Cookies from 'utils/cookies'

const login = function* ({ payload }) {
  const { meta, ...data } = payload

  try {
    const res = yield call(authService.login, data)

    Cookies.setToken(res.access_token, res.expires_in)

    yield put(authActions.loginSuccess(data))

    if (meta && meta.onSuccess) {
      meta.onSuccess(res)
    }
  } catch (error) {
    yield put(authActions.loginError(error))
    if (meta && meta.onError) {
      meta.onError(error)
    }
  }
}

const logout = function* ({ payload = { onSuccess: () => {}, onError: () => {} } }) {
  const { onSuccess, onError } = payload

  try {
    yield call(authService.logout)
    yield call(Cookies.remove('token'))

    if (window.location.href.indexOf('/login') === -1) window.location.href = domainForlifeHome

    if (onSuccess) onSuccess()
  } catch (error) {
    if (onError) onError()
  }
}

export default function* authSaga() {
  yield takeLatest(authActions.login.type, login)
  yield takeLatest(authActions.logout.type, logout)
}
