import { SHARE_COOKIE_DOMAIN } from 'config'
import _ from 'lodash'
import jsCookies from 'js-cookie'

export default class Cookies {
  static get(key) {
  }

  static set(key, value) {
  }
  
  static remove(key) {
    return jsCookies.remove(key)
  }

  static removeToken =  () => {
    return jsCookies.remove('token', { path: '/', domain: SHARE_COOKIE_DOMAIN })
  }

  static getToken = () => {
    const data = jsCookies.get('token')

    return data || null
  }

  static setToken = async (token, expiresIn) => {
    await jsCookies.set('token', token, { expires: parseInt(expiresIn), domain: SHARE_COOKIE_DOMAIN })
  }
}
