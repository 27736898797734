export const HTTP_STATUS = {
  SUCCESS: 200,
  CREATED_SUCCESS: 201,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500,
  SERVICE_UNAVAILABLE: 503,
  OTHER: -1,
}

export const MESSAGE_TYPES = {
  SUCCESS: 'success',
  ERROR: 'error',
}

export const URLs = {}
