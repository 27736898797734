import axios from 'axios'
import Cookies from 'utils/cookies'
import { authActions } from '../redux/slices/authSlice'
import { store } from '../redux/store'
import { isValidUrl } from '../utils/common'

const TIMEOUT = 5000

let refreshingToken = false

export const funcFetch = async (endPoint, data = null, method = 'get', headers = {}) => {
  headers['Accept-Language'] = 'vn'

  const token = await Cookies.getToken()

  if (token) {
    headers = {
      ...headers,
      Authorization: 'Bearer ' + token,
    }
  }

  return axios({
    url: isValidUrl(endPoint) ? endPoint : process.env.REACT_APP_API_URL + endPoint,
    method,
    headers,
    data,
    timeout: TIMEOUT,
  })
    .then((res) => {
      const { status, data } = res

      if (status < 300) {
        if (data) return data.data
      } else {
        throw new Error(data.message)
      }

      return Promise.reject(res.error)
    })
    .catch((error) => {
      const {
        response: { status, data },
      } = error

      // if (status === 401 || status === 403) {
      //   store.dispatch(authActions.logout())
      // } else {
      //   console.log(error)
      // }

      throw new Error(data?.message || '')
    })
}

const fetchApi = async (endPoint = '', data = null, method = 'get', headers = {}) => {
  return funcFetch(endPoint, data, method, headers)
}

export default fetchApi
